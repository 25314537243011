<template>
    <div>
        <footer class="footer p-5" >
            <div class="row">
                <div class="col-md-4" >
                    <div>
                        <h4>Contacto</h4>
                        <small>Escanea o Presiona el Codigo QR para iniciar un chat.</small>
                    </div>
                    <br>
                    <a href="https://wa.me/525533138568?text=Hola!%20Podemos%20agendar%20una%20cita?" target="_blank" rel="noopener noreferrer">
                        <qrcode-vue style="background-color:#fff" class="p-2" value="https://wa.me/525533138568?text=Hola!%20Podemos%20agendar%20una%20cita?" :size="250" />

                    </a>
                </div>
                <div class="col-md-3">
                    <div>
                        <h4>Sigue Nuestras Redes Sociales</h4>
                    </div>
                    <br>
                    <div class="w3-xlarge">
                        <a href="https://www.facebook.com/cap.wash.2024?mibextid=ZbWKwL" target="_blank">
                            <i
                                class="p-2 fa fa-facebook-official w3-hover-opacity"
                            ></i>
                        </a>
                        <a href="https://www.instagram.com/se_lava_ajeno_capwash?utm_source=qr&igsh=NXpoNjFyamd4a3lx" target="_blank">
                            <i class="p-2 fa fa-instagram w3-hover-opacity"></i>
                        </a>
                        <a href="">
                            <i class="p-2 fa fa-twitter w3-hover-opacity"></i>
                        </a>
                        <a href="">
                            <i class="p-2 fa fa-youtube w3-hover-opacity"></i>
                        </a>
                        <a href="">
                            <small class="p-2">TikTok</small>
                        </a>
                    </div>
                    <br>
                    <div>   
                        <router-link to="terminos" class="txt-dec"><small>Terminos y condiciones</small></router-link>
                        <br />

                    </div>
                </div>
                <div class="col-md-2">
                    
                </div>

            </div>
            <br />
            <div class="text-center">
                <small>Hecho en México, {{ date }}</small>
                <br />
                <small
                    >Sitio Web Administrado por
                    <a
                        href="http://"
                        target="_blank"
                        rel="noopener noreferrer"
                    ></a
                    ><a href="http://rethack.rf.gd" target="_blank" rel="noopener noreferrer"> RET Hack</a></small
                >
            </div>
        </footer>
    </div>
</template>

<script>    
import QrcodeVue from 'qrcode.vue'

export default {
    components:{
            QrcodeVue
    },
    data() {
        return {
            date: new Date().getFullYear(),
        };
    },
};
</script>

<style scoped>
.footer {
    background-color: #222;
    color: #fff;
    bottom: 0;
}
.txt-dec{
    text-decoration: none;
}
</style>
    